.Partners {
  header {
    max-width: calc(587rem / 16);
    margin: 0 auto clamp(32px, calc(48vw / 7.68), 48px);
    text-align: center;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    $gap: clamp(16px, calc(24vw / 7.68), 32px);
    gap: $gap;
    .item {
      border-radius: 6px;
      background: var(--primary-300, #fff6f9);
      transition: transform 0.3s var(--easing);
      &:hover {
        transform: translateY(-3px);
      }
      width: calc(100% / 4 - $gap * (1 - 1 / 4));
      @media (max-width: 999px) {
        width: calc(100% / 3 - $gap * (1 - 1 / 3));
      }
      @media (max-width: 499px) {
        width: calc(100% / 2 - $gap * (1 - 1 / 2));
      }
    }
    .container {
      display: block;
      padding: clamp(28px, calc(36vw / 7.68), 36px) clamp(16px, calc(28vw / 7.68), 28px);
    }
    img {
      width: 100%;
      max-height: 80px;
      min-height: 28px;
      object-fit: contain;
    }
  }
}
